




























































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import * as echarts from 'echarts/core'
import { BarChart, LineChart } from 'echarts/charts'
import {
    apiWorkbenchIndex,
    apiWorkbenchTopUser,
    apiWorkbenchTopGoods
} from '@/api/home'
import LsPagination from '@/components/ls-pagination.vue'
import {
    GridComponent,
    TitleComponent,
    LegendComponent,
    PolarComponent
} from 'echarts/components'
// 注意，新的接口中默认不再包含 Canvas 渲染器，需要显示引入，如果需要使用 SVG 渲染模式则使用 SVGRenderer
import { CanvasRenderer } from 'echarts/renderers'
import { RequestPaging, timeFormat } from '@/utils/util'
import { PageMode } from '@/utils/type'

echarts.use([
    BarChart,
    GridComponent,
    CanvasRenderer,
    TitleComponent,
    LegendComponent,
    PolarComponent,
    LineChart
])
@Component({
    components: {
        LsPagination
    }
})
export default class Home extends Vue {
    visitor = {
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: ['配送量']
        },
        xAxis: {
            type: 'category',
            data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
            splitLine: {
                show: true, // 是否显示分隔线。默认数值轴显示，类目轴不显示
                interval: '1' // 坐标轴刻度标签的显示间隔，在类目轴中有效.0显示所有
            }
        },
        yAxis: {
            // type: "category"
        },
        series: [
            {
                name: '配送量',
                type: 'line',
                stack: '总量',
                data: [1,2,3,4,5,6,7]
            }
        ]
    }

    business = {
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: ['营业额', '商品数量', '订单数量']
        },
        xAxis: {
            type: 'category',
            data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
            splitLine: {
                show: true, // 是否显示分隔线。默认数值轴显示，类目轴不显示
                interval: '1' // 坐标轴刻度标签的显示间隔，在类目轴中有效.0显示所有
            }
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                name: '营业额',
                type: 'line',
                stack: '总量',
                data: []
            },
            {
                name: '商品数量',
                type: 'line',
                stack: '总量',
                data: []
            },
            {
                name: '订单数量',
                type: 'line',
                stack: '总量',
                data: []
            }
        ]
    }

    indexData: any = {
        shop_info: {
            logo: ''
        },
        today: {
            today_order_num: '',
            today_order_amount: '',
            today_new_user: '',
            today_visitor: ''
        },
        pending: {
            wait_shipped: '',
            wait_audit: '',
            wait_reply: '',
            no_stock_goods: ''
        }
    }

    pagerTopGoods: RequestPaging = new RequestPaging()
    pagerTopUser: RequestPaging = new RequestPaging()

    getWorkbenchIndexData() {
        // 清空echarts 数据
        this.business.xAxis.data = []
        this.business.legend.data = []
        // this.business.series = [];

        apiWorkbenchIndex({}).then(res => {
            this.indexData = res
            res.business15.list[0].data = res.business15.list[0].data.reverse()
            // res.visitor15.list[0].data = res.visitor15.list[0].data.reverse()

            this.business.xAxis.data = res.business15.date
            console.log("res", res)

            this.visitor.xAxis.data = res.visitor15.date

            res.business15.list.forEach((item: any, index: number) => {
                this.business.series[index].data = item.data
                this.business.series[index].name = item.name
                this.business.legend.data[index] = item.name
            })

            res.visitor15.list.forEach((item: any, index: number) => {
                let arr = []
                for(let key in item.data) {
                    arr.push(item.data[key][0])
                }
                this.visitor.series[index].data = arr
                this.visitor.series[index].name = item.name
                this.visitor.legend.data[index] = item.name
            })
            console.log("res.visitor15.list[0].data.", res.visitor15.list[0].data)

         
        })
    }

    goPage(url: string) {
        if(url == '/delivery/report') {
            let date:any = new Date()
            let delivery_date = timeFormat(date)
            sessionStorage.setItem('delivery_date', delivery_date)
            sessionStorage.setItem('status', '3')
            this.$router.push({
                path: url
            })
            
            return;
        }
        this.$router.push({
            path: url
        })
    }

    // 商品top50
    getTopGoods(): void {
        this.pagerTopGoods
            .request({
                callback: apiWorkbenchTopGoods
            })
            .then((res: any) => {})
    }

    // 用户top50
    getTopUser(): void {
        this.pagerTopUser
            .request({
                callback: apiWorkbenchTopUser
            })
            .then((res: any) => {})
    }

    created() {
        this.getWorkbenchIndexData()
        this.getTopGoods()
        this.getTopUser()
    }
}
